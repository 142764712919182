@keyframes move {
    from {
      left: 100%;
    }
    to {
      left: -150px;
    }
  }
  .car {
    position: absolute;
    left: 0;
    bottom: 0px;
    animation: move 6s linear 0s infinite;
    width: 18%;
  }